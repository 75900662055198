import React from "react"
import useTranslations from "../../../utils/useTranslations"
import { Faq } from "../bittorrent-speed/faq"
import heroImg from "../../../images/token/bittorrent-file-system/btfs-logo.svg"
import backgrounds from "../../../components/backgrounds"
import { parseFaq } from "../../../utils/wordpress"

const TokenBittorrentFileSystemFAQ = ({ pageContext: {text}, data}) => {
const t = useTranslations(text)
const heroImgAlt = "BTFS Logo"
const parsedFaqData = parseFaq(data.allWordpressWpFaqSect.edges, data.allWordpressWpCustomFaqs.edges)

  return (
  	<>
        {/* Start - hero */}
        <div className="top-section-container py-5 background-noise-dark" style={backgrounds.darkBg}>
          <div className="container py-5 text-center">

          	<img src={heroImg} alt={heroImgAlt} className="img-fluid pb-5 btfs-logo"></img>
          	<p className="text-size-30 text-white btfs-hero-title-1">{t(`Frequently Asked Questions`)}</p>
          	<h1 className="text-size-20 text-white btfs-hero-title-2 font-weight-normal">{t(`Find in-depth information about BTFS here`)}</h1>

          </div>
        </div> 
        {/* End - Hero */}

	    <div className="container my-5">
	      <Faq data={parsedFaqData} />
	    </div>
	</>
  )
}

export default TokenBittorrentFileSystemFAQ

export const pageQuery = graphql`
query queryBtfsFaq {
  allWordpressWpFaqSect {
    edges {
      node {
        name
        wordpress_id
      }
    }
  }
  allWordpressWpCustomFaqs(
    sort: {fields: menu_order, order: ASC},
    filter: {faq_cat: {eq: 1803}, faq_sect: {ne: 1816}}
  ) {
    edges {
      node {
        title
        content
        wordpress_id
        id
        menu_order
        faq_cat
        faq_sect
      }
    }
  }
}

`

